var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _Globals_state;
// @ts-nocheck
import AppEvents from './app-events.js';
const SESSION_GLOBALS = 'app-session-globals';
export function storeSessionGlobals(globals) {
    window.sessionStorage.setItem(SESSION_GLOBALS, JSON.stringify(globals));
}
export function getSessionGlobals() {
    const stored = window.sessionStorage.getItem(SESSION_GLOBALS);
    return stored && JSON.parse(stored);
}
export default class Globals {
    constructor(init = getSessionGlobals()) {
        _Globals_state.set(this, {
        // TODO: add your globals here
        });
        this.update(Object.assign({}, init));
    }
    update(globals) {
        // TODO: add your globals here
        storeSessionGlobals(__classPrivateFieldGet(this, _Globals_state, "f"));
        this.dispatchUpdate();
    }
    dispatchUpdate() {
        window.dispatchEvent(new CustomEvent(AppEvents.GLOBALS_UPDATED, {
            detail: __classPrivateFieldGet(this, _Globals_state, "f"),
        }));
    }
    /**
     * @function onUpdate
     * @description Adds an event listener for global updates
     * @param {(e: CustomEvent) => void} callback - The function to be called on update event
     * @returns {Function} - Cleanup function to remove the event listener when called
     */
    onUpdate(callback) {
        window.addEventListener(AppEvents.GLOBALS_UPDATED, callback);
        return () => window.removeEventListener(AppEvents.GLOBALS_UPDATED, callback);
    }
}
_Globals_state = new WeakMap();
